import BSN from "bootstrap.native"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  // static targets = ["dropdown"]

  toggle(event) {
    event.preventDefault();
    let target = event.currentTarget;
    let dropdown = new BSN.Dropdown(target);
    dropdown.toggle();
  }

}
