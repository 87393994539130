import BSN from "bootstrap.native"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["truncate", "more_less", "text"]

  show_more(e) {
    e.preventDefault();
    const div = this.truncateTarget;
    const more_text = this.data.get("large-text");
    const less_link_text = this.data.get("less-link-text");
    const less_text = div.innerHTML;
    div.innerHTML = "<span>" + more_text + "<a href='#' class='text-info' data-action='click->layout--truncate#show_less'> " + less_link_text + " </a></span>";
    this.data.set("less-text", less_text);
  }

  show_less(e) {
    e.preventDefault();
    const div = this.truncateTarget;
    const less_text = this.data.get("less-text");
    div.innerHTML = less_text;
  }
}