import BSN from "bootstrap.native"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["dropdown"]

  initialize() {
    //
    // tooltip
    //
    if (this.hasDropdownTarget) {
      let dropdown_target = this.dropdownTarget;
      new BSN.Dropdown(dropdown_target)
    }
  }
}