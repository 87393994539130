import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {

  initialize() {

  }

  removeRestoreFileClick(event) {
    event.preventDefault();

    let icon = event.target;
    let _destroy_input = event.target.closest("tr").children[1];
    let filename = event.target.closest("tr").children[3].children[0];

    if (!icon.classList.contains("btn-link")) {
      if (_destroy_input.value == "false") {
        icon.classList = "fas fa-trash-restore-alt";
        _destroy_input.value = "true";
        filename.innerHTML = filename.textContent.strike();
      }
      else {
        icon.classList = "fas fa-trash-alt";
        _destroy_input.value = "false";
        filename.innerHTML = filename.textContent;
      }
    }
  }
}
