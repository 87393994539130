import { DirectUpload } from "@rails/activestorage";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["file_preview_current", "file_input",
    "file_preview_selected", "current_file_delete", "selected_file_delete"];

  startUpload(event) {
    let file_input = event.currentTarget;
    const selectedFile = file_input.files[0];
    this.uploadFile(file_input, selectedFile)
    file_input.value = null
  }

  selectedFileDelete(event) {
    //
    const message = event.currentTarget.getAttribute("data-confirm-message")
    if (confirm(message)) {
      const previewImg = this.file_preview_selectedTarget;
      previewImg.innerHTML = ""
      this.showCurrentHideSelectedFile();
    }
    event.preventDefault();
  }


  // private
  showCurrentHideSelectedFile() {
    if (this.hasFile_preview_currentTarget)
      this.file_preview_currentTarget.classList.remove("hidden");

    if (this.hasCurrent_file_deleteTarget)
      this.current_file_deleteTarget.classList.remove("hidden");

    if (this.hasFile_preview_selectedTarget)
      this.file_preview_selectedTarget.classList.add("hidden");

    if (this.hasSelected_file_deleteTarget)
      this.selected_file_deleteTarget.classList.add("hidden");

  }

  hideCurrentShowSelectedFile() {
    if (this.hasFile_preview_currentTarget)
      this.file_preview_currentTarget.classList.add("hidden");

    if (this.hasCurrent_file_deleteTarget)
      this.current_file_deleteTarget.classList.add("hidden");

    if (this.hasFile_preview_selectedTarget)
      this.file_preview_selectedTarget.classList.remove("hidden");

    if (this.hasSelected_file_deleteTarget)
      this.selected_file_deleteTarget.classList.remove("hidden");
  }


  uploadFile(file_input, file) {
    const url = file_input.dataset.directUploadUrl

    const upload = new DirectUpload(file, url)

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        const previewImg = this.file_preview_selectedTarget;
        previewImg.innerHTML = "";

        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = file_input.name
        previewImg.insertAdjacentElement('afterbegin', hiddenField)
        this.insertPreview(file);
      }
    })

  }

  insertPreview(file) {
    // image
    const previewImg = this.file_preview_selectedTarget;
    // console.log(file.name);
    if (file.type.startsWith('image/')) {
      const img = document.createElement("img");
      img.classList.add("obj");
      img.file = file;

      previewImg.insertAdjacentElement('afterbegin', img)

      const reader = new FileReader();
      reader.onload = (function (aImg) { return function (e) { aImg.src = e.target.result; }; })(img);
      reader.readAsDataURL(file);

    }
    else {
      const name = file.name;
      const lastDot = name.lastIndexOf('.');
      const ext = name.substring(lastDot + 1);
      const file_icon = document.createElement("i");

      if (ext == "pdf") file_icon.classList.add("fas", "fa-file-pdf", "fa-2x", "pdf");
      else if (ext == "xlsx" || ext == "xlsx") file_icon.classList.add("fas", "fa-file-excel", "fa-2x", "excel");
      else if (ext == "doc" || ext == "docx") file_icon.classList.add("fas", "fa-file-word", "fa-2x", "word");
      else if (ext == "zip" || ext == "rar") file_icon.classList.add("fas", "fa-file-archive", "fa-2x", "archive");
      else file_icon.classList.add("fas", "fa-file-upload", "fa-2x", "archive");



      previewImg.insertAdjacentElement('afterbegin', file_icon)
      // const reader = new FileReader();
      // reader.onload = (function (aImg) { return function (e) { aImg.src = e.target.result; }; })(img);
      // reader.readAsDataURL(file);
    }

    this.hideCurrentShowSelectedFile();
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    // console.log("Upload has some progress ....")
  }

}