import BSN from "bootstrap.native"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["body", "header__navbar", "sidebar", "main_content",
    "sidebar_collapse", "sidebar_closed", "backdrop", "filters_content",
    "layout_modal", "layout_modal_md", "layout_modal_lg", "layout_modal_xl"]

  connect() {
    if (this.hasLayout_modalTarget) {
      window.layout_modal = new BSN.Modal(this.layout_modalTarget, {
        backdrop: "static",
        keyboard: true
      });

      // reset modal connect, remove leave page from form
      this.layout_modalTarget.addEventListener('hide.bs.modal', function (event) {
        window.layout_modal.setContent("");
      }, false);
    }

    if (this.hasLayout_modal_xlTarget) {
      console.log(1);
      window.layout_modal_xl = new BSN.Modal(this.layout_modal_xlTarget, {
        backdrop: "static",
        keyboard: true
      });

      // reset modal connect, remove leave page from form
      this.layout_modal_xlTarget.addEventListener('hide.bs.modal', function (event) {
        window.layout_modal_xl.setContent("");
      }, false);
    }

    if (this.hasLayout_modal_lgTarget) {
      window.layout_modal_lg = new BSN.Modal(this.layout_modal_lgTarget, {
        backdrop: "static",
        keyboard: true
      });

      // reset modal connect, remove leave page from form
      this.layout_modal_lgTarget.addEventListener('hide.bs.modal', function (event) {
        window.layout_modal_lg.setContent("");
      }, false);
    }

    if (this.hasLayout_modal_mdTarget) {
      window.layout_modal_md = new BSN.Modal(this.layout_modal_mdTarget, {
        backdrop: "static",
        keyboard: true
      });

      // reset modal connect, remove leave page from form
      this.layout_modal_mdTarget.addEventListener('hide.bs.modal', function (event) {
        window.layout_modal_md.setContent("");
      }, false);
    }
  }

  // change currency
  changeCurrency(event) {
    event.preventDefault();
    const link_target = event.currentTarget
    const url = link_target.getAttribute("data-url")
    const current_currency = link_target.innerHTML;
    this.changeCurrencyRequest(url, current_currency)
  }

  // change currency send requert
  async changeCurrencyRequest(url, current_currency) {
    await fetch(url).then(function (response) {
      if (response.status == 422) {
        response.text().then(function (text) {
          console.log(text)
        });
      } else {
        response.text().then(function (text) {
          let json = JSON.parse(text)
          document.body.classList.remove(...json.remove_class)
          document.body.classList.add(json.add_class)
          document.getElementById("header_current_currency").innerHTML = current_currency;
        });
      }
    })
  }


  // history.back
  back(event) {
    event.preventDefault();
    history.go(-1);
  }

  // navbar-toggle click
  //
  navbar_toggle_click(event) {
    // let target = event.currentTarget;
    // console.log("navbar-toggle click")
    event.preventDefault();
    if (this.hasHeader__navbarTarget) {
      this.header__navbarTarget.classList.add('header__navbar--open');
    }

    if (this.hasBodyTarget) {
      this.bodyTarget.classList.add('navbar--open');
    }
  }

  // .backdrop click
  //
  backdrop_click(event) {
    // let target = event.currentTarget;
    event.preventDefault();

    if (this.hasHeader__navbarTarget) {
      this.header__navbarTarget.classList.remove('header__navbar--open');
    }

    if (this.hasSidebarTarget) {
      this.sidebarTarget.classList.remove('sidebar--open');
    }

    if (this.hasBodyTarget) {
      this.bodyTarget.classList.remove('navbar--open');
      this.bodyTarget.classList.remove('sidebar--opened');
    }
  }

  filter_button_toogle_click(event) {
    event.preventDefault();

    if (this.hasFilters_contentTarget) {
      this.filters_contentTarget.classList.toggle("collapse");
    }

  }

  // toggle sidebar sidebar-toggle.click
  sidebar_toggle_click(event) {
    // let target = event.currentTarget;
    event.preventDefault();

    if (this.hasSidebarTarget) {
      this.sidebarTarget.classList.add('sidebar--open');
    }

    if (this.hasBodyTarget) {
      this.bodyTarget.classList.add('sidebar--opened');
    }
  }

  // Collapse sidebar on click or resized till min-width
  collapseSidebar() {
    if (this.hasSidebarTarget) {
      this.sidebarTarget.classList.toggle('sidebar--closed')
    }

    if (this.hasMain_contentTarget) {
      this.main_contentTarget.classList.toggle('full-width-content')
    }

  }

  // sidebar-collapse click
  sidebar_collapse_click(event) {
    // let target = event.currentTarget;
    event.preventDefault();
    this.collapseSidebar();
  }

  // sidebar_closed click
  sidebar_closed_click(event) {
    let target = event.currentTarget;
    if (target.classList.contains('sidebar--closed')) {
      target.classList.remove('sidebar--closed');
    }
  }

}
