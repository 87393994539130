import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["drop_container", "file"]

  initialize() {
    const dropContainer = this.drop_containerTarget
    const fileInput = this.fileTarget

    this.set_listeners(dropContainer, fileInput)
  }

  set_listeners(dropContainer, fileInput){
    dropContainer.addEventListener("dragover", (e) => {
      e.preventDefault()
    }, false)

    dropContainer.addEventListener("dragenter", () => {
      dropContainer.classList.add("drag-active")
    })

    dropContainer.addEventListener("dragleave", () => {
      dropContainer.classList.remove("drag-active")
    })

    dropContainer.addEventListener("drop", (e) => {
      e.preventDefault()
      dropContainer.classList.remove("drag-active")
      fileInput.files = e.dataTransfer.files
    })
  }

}