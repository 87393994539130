import BSN from "bootstrap.native"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["tooltip"]

  initialize() {
    //
    // tooltip
    //
    if (this.hasTooltipTarget) {
      let tooltip_target = this.tooltipTarget;
      new BSN.Tooltip(tooltip_target)
    }
  }
}