//
// validations and leave message on chnage form
//
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {

  initialize() {
    this.leavingPageEvent = this.leavingPage.bind(this);
    this.leaveMessage = document.body.dataset.leaveFormMessage;
  }

  connect() {
    this.element[this.identifier] = this;
    this.element.setAttribute('novalidate', true)
    this.element.addEventListener('blur', this.onBlur, true)
    this.element.addEventListener('submit', this.onSubmit)
    this.element.addEventListener('ajax:beforeSend', this.onSubmit)

    // enter to next
    this.element.addEventListener('keydown', this.onKeydown, true)

    // set form isChange to false
    if (this.data.get("leaving-notify") == "true") {
      this.setChanged(false)
      this.addOnChange()
      window.addEventListener("beforeunload", this.leavingPageEvent)
      window.addEventListener("turbolinks:before-visit", this.leavingPageEvent)
    }
  }

  disconnect() {
    this.element.removeEventListener('blur', this.onBlur)
    this.element.removeEventListener('submit', this.onSubmit)
    this.element.removeEventListener('ajax:beforeSend', this.onSubmit)

    // leave page
    this.removeOnChange()
    window.removeEventListener("beforeunload", this.leavingPageEvent)
    window.removeEventListener("turbolinks:before-visit", this.leavingPageEvent)
  }

  onBlur = (event) => {
    let target = event.target;
    let fieldContainer = target.closest('.form-group');
    if (target.classList.contains("choices__input") || target.classList.contains("choices")) {
      // choices multiselect
      // console.log(event.type)
      // if (event.type == "change" && )
      // this.checkChoicesMulti(fieldContainer);

    } else if (target.type) {
      // other
      this.validateField(target)
    }
  }

  onSubmit = (event) => {
    this.allowFormSubmission(event)
    if (!this.validateForm()) {
      event.preventDefault()
      let focus_field = this.firstInvalidField
      if (focus_field) focus_field.focus()
      // this.firstInvalidField.focus()
    }
  }

  onChanged = (event) => {
    let target = event.target;
    if (target) this.setChanged(true)
  }

  onKeydown = (event) => {
    if (event.keyIdentifier == 'U+000A' || event.keyIdentifier == 'Enter' || event.keyCode == 13) {
      if (event.target.nodeName == 'INPUT') {
        event.preventDefault();
        let focus_field = this.firstInvalidField
        if (focus_field) focus_field.focus()
        return false;
      }
    }
  }

  isFormChanged() {
    return this.data.get("changed") == "true";
  }

  leavingPage(event) {
    if (this.isFormChanged()) {
      if (event.type == "turbolinks:before-visit") {
        if (!window.confirm(this.leaveMessage)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = this.leaveMessage;
        return event.returnValue;
      }
    }
  }
  //
  // Private
  //
  allowFormSubmission(event) {
    this.setChanged("false")
  }

  // is changed form
  setChanged(changed) {
    this.data.set("changed", changed)
  }

  // add trigger to form fieds
  addOnChange() {
    this.formFields.forEach((field) => {
      field.addEventListener("change", this.onChanged)
    })
    this.trixElements.forEach((field) => {
      field.addEventListener("trix-selection-change", this.onChanged)
    })
  }

  // remove trigger to form fieds
  removeOnChange() {
    this.formFields.forEach((field) => {
      field.removeEventListener("change", this.onChanged)
    })
    this.trixElements.forEach((field) => {
      field.removeEventListener("trix-selection-change", this.onChanged)
    })
  }

  //
  // VALIDATE
  //
  checkCalendar(field) {
    // let select_fiels = div_target.getElementsByTagName("select");
    // for (let field of select_fiels) {
    if (field) this.validateField(field);
    // }
  }


  checkChoicesMulti(div_target) {
    let select_fiels = div_target.getElementsByTagName("select");
    for (let field of select_fiels) {
      // console.log(field)
      if (field) this.validateField(field);
    }
  }

  validateForm() {
    let isValid = true
    // Not using `find` because we want to validate all the fields
    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) isValid = false
    })
    return isValid
  }

  validateField(field) {
    if (typeof field !== "undefined") {
      if (!this.shouldValidateField(field))
        return true

      const isValid = this.checkFieldValidity(field);
      // field.classList.toggle('is-invalid', !isValid)
      this.refreshErrorForInvalidField(field, isValid)
      return isValid
    }
    else {
      return true
    }
  }

  checkFieldValidity(field) {
    if (field.classList.contains("greater_than_zero_input") && field.value <= 0) {
      return false;
    } else if (field.classList.contains("required_input") && !field.value) {
      return false;
    } else {
      return field.checkValidity();
    }
  }


  shouldValidateField(field) {
    return !field.disabled && !['file', 'reset', 'submit', 'button'].includes(field.type)
  }

  async refreshErrorForInvalidField(field, isValid) {
    await this.removeExistingErrorMessage(field)
    if (!isValid)
      this.showErrorForInvalidField(field)
  }

  removeExistingErrorMessage(field) {
    const fieldContainer = field.closest('.form-group')
    if (fieldContainer) {
      const existingErrorMessageElement = fieldContainer.querySelector('.error-message');
      fieldContainer.classList.remove('error');

      if (existingErrorMessageElement)
        existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
      // existingErrorMessageElement.innerHTML = "&nbsp;"
    }

    field.classList.remove('is-invalid');

    const choicesFieldContainer = field.closest('.choices')
    if (choicesFieldContainer)
      choicesFieldContainer.classList.remove('error');

    if (field.classList.contains("contact_input")) {
      field.closest(".input-group").classList.remove('error');
    }

  }

  showErrorForInvalidField(field) {
    if (["select-one", "select-multiple"].includes(field.type)) {
      this.showErrorForInvalidChoices(field);
    } else if (field.getAttribute("data-validate") == "mini") {
      this.showErrorMini(field);
    } else if (field.classList.contains("contact_input")) {
      this.showErrorForInvalidContact(field);
    } else if (field.classList.contains("phone_input")) {
      this.showErrorForInvalidPhone(field);
    } else if (field.classList.contains("group_input")) {
      this.showErrorForInvalidGroupInput(field);
    } else {
      field.classList.add('is-invalid');
      field.insertAdjacentHTML('afterend', this.buildFieldErrorHtml(field))
    }
  }

  showErrorMini(field) {
    field.classList.add('is-invalid');
  }

  showErrorForInvalidGroupInput(field) {
    field.classList.add('is-invalid');
    const fieldContainer = field.closest('.form-group')
    // field.closest(".input-group").classList.toggle('error');
    fieldContainer.insertAdjacentHTML('beforeend', this.buildFieldErrorHtml(field))
  }

  showErrorForInvalidPhone(field) {
    field.classList.add('is-invalid');
    const fieldContainer = field.closest('.form-group')
    // field.closest(".input-group").classList.toggle('error');
    fieldContainer.insertAdjacentHTML('beforeend', this.buildFieldErrorHtml(field))
  }

  showErrorForInvalidContact(field) {
    const fieldContainer = field.closest('.form-group')
    field.closest(".input-group").classList.toggle('error');
    fieldContainer.insertAdjacentHTML('beforeend', this.buildFieldErrorHtml(field))
  }

  showErrorForInvalidChoices(field) {
    const fieldContainer = field.closest('.choices')
    fieldContainer.classList.toggle('error');
    if (field.getAttribute("data-validate") != "mini") {
      const existingErrorMessageElement = field.closest('.form-group').querySelector('.error-message');

      if (existingErrorMessageElement)
        existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)

      fieldContainer.insertAdjacentHTML('afterend', this.buildFieldErrorHtml(field))
    }
  }

  buildFieldErrorHtml(field) {
    let msg;

    if (!field.value)
      msg = field.getAttribute("data-require-msg")
    else if (field.classList.contains("greater_than_zero_input") && field.value <= 0)
      msg = field.getAttribute("data-greater-than-zero-msg")

    if (!msg)
      msg = field.validationMessage;

    return `<span class="error-message">${msg}</span>`
  }

  get formFields() {
    return Array.from(this.element.elements)
  }

  get firstInvalidField() {
    // console.log(this.formFields);

    // return this.formFields.find(field => !field.checkValidity())
    return this.formFields.find(field => !this.checkFieldValidity(field))
  }

  get trixElements() {
    return Array.from(this.element.querySelectorAll("trix-editor"))
  }
}
