import BSN from "bootstrap.native"
import Split from "../split"
import StickySidebar from "sticky-sidebar"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["sidebar_filter"]

  connect() {
    // console.log("sidebar connect");
    // let height = document.body.clientHeight;
    let width = document.body.clientWidth;
    let sidebar = document.querySelector('#sidebar');
    let content = document.querySelector('#content')

    let breakpoint = 992;

    let sizesLocal = localStorage.getItem('split-sizes')
    let sizes = [18, 80];
    if (sizesLocal) {
      sizes = JSON.parse(sizesLocal)
    }
    if (width > breakpoint) {

      if (sidebar && content) {
        function widthChangeHandler(_el, _parent) {
          let elem = document.querySelector(_el);
          let parWidth = document.querySelector(_parent).offsetWidth;
          elem.style.width = parWidth + 'px';
        }

        let split = Split(['#sidebar', '#content'], {
          sizes: sizes,
          gutterSize: 6,
          // minSize: [290, 400],
          onDrag: function () {
            widthChangeHandler('.sidebar__inner-sticky', '.sidebar')
          },
          onDragEnd: function (sizes) {
            localStorage.setItem('split-sizes', JSON.stringify(sizes));
          },
        });
      }

      // Sticky sidebar
      if (sidebar) {
        sidebar.sidebar = new StickySidebar('#sidebar', {
          resizeSensor: true,
          containerSelector: '.main-content__inner',
          innerWrapperSelector: '.sidebar__inner-sticky',
          topSpacing: 115,
          bottomSpacing: 0,
          stickyClass: 'is-affixed',
        });
      }
    }

  }

  dropdownFilter(event) {
    if (this.hasSidebar_filterTarget) {
      const filter = this.sidebar_filterTarget;
      filter.classList.toggle('show');
    }
    event.preventDefault();
  }

  dropdownSort(event) {
    event.preventDefault();
    let target = event.currentTarget;
    let dropdown = new BSN.Dropdown(target);
    dropdown.toggle();
  }
}
