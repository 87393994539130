import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["page_filter", "export_url", "view_type_url"]

  getFiltersValues() {
    let filters = {};
    if (this.hasPage_filterTarget) {
      this.page_filterTargets.forEach(el => {
        if ((el.choices && el.choices.getValue(true).length) || el.value) {
          switch (el.name) {
            case "search":
              filters["q"] = el.value;
              break;

            case "supplier[]":
              filters["supplier_ids"] = el.choices.getValue(true);
              break;
            case "deals_ids[]":
              filters["deal_ids"] = el.choices.getValue(true);
              break;
            case "service_types[]":
              filters["service_type_ids"] = el.choices.getValue(true);
              break;
            case "suppliers_ids[]":
              filters["supplier_ids"] = el.choices.getValue(true);
              break;
            case "accounts_ids[]":
              filters["account_ids"] = el.choices.getValue(true);
              break;

            default:
              if (el.name.match(/\[\]$/)){
                filters[el.name.split(/\[\]/)[0]] = el.choices.getValue(true);
              }
              else {
                filters[el.name] = el.value;
              }
              break;
          }
        }
      })
    }

    return filters;
  }

  columnsSelectClick(e) {
    e.preventDefault();

    let button = e.currentTarget;
    button.disabled = true;

    let form = button.closest("form");
    let url = form.action;
    let filters = new URLSearchParams(this.getFiltersValues());
    const content_element = document.getElementById("content");
    const formData = new FormData(form);
    for (let pair of formData.entries()) {
      filters.append(pair[0], pair[1]);
    }
    filters.append("load_type", "columns_select");
    url += "?" + filters;

    fetch(url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          content_element.innerHTML = JSON.parse(content)["page_content"];
        })
      }
    });
  }

  pageLinkClick(e) {
    e.preventDefault();
    let button = e.currentTarget;
    button.classList.add('disabled');

    let filters = new URLSearchParams(this.getFiltersValues());
    let page_url = e.currentTarget.href;

    if (filters.toString())
      page_url += "?" + filters

    let a = document.createElement('a');
    a.href = page_url;
    document.body.appendChild(a);
    a.click();
  }

  exportClick(e) {
    e.preventDefault();
    let button = e.currentTarget;
    button.classList.add('disabled');

    let filename = '';
    let filter_name = '';
    let filters = this.getFiltersValues();
    // let download_url = this.export_urlTarget.href + "?" + new URLSearchParams(filters)
    const button_url = button.href
    let download_url = button_url + "?" + new URLSearchParams(filters)

    fetch(download_url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/html',
        'Accept': 'application/html'
      },
      credentials: 'same-origin'
    }).then(response => {
      filename = response.headers.get('Content-Disposition');
      filename = filename.match(/(?<=")(?:\\.|[^"\\])*(?=")/)[0];
      return response.blob();
    }).then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename; // "filename.xlsx";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();  //afterwards we remove the element again
    });
    button.classList.remove('disabled');
  }
}
