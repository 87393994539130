import BSN from "bootstrap.native"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["main_tab", "tab"]

  connect() {
    // let tab_name = this.data.get("tab-name");
    // let last_active_tab = window.localStorage.getItem(tab_name);
    // // let tabs = this.tabTargets;
    this.init();
  }

  open(event) {
    // let tab_name = this.data.get("tab-name");
    // let target = event.currentTarget;
    // window.localStorage.setItem(tab_name, target.getAttribute("id"));
  }

  // add_tab_content_class(content_id) {
  //   // let content_id = tab.getAttribute("href");
  //   // let content = document.querySelectorAll(content_id);
  //   // if (content.length > 0) {
  //   //   content.classList.add("active");
  //   // }
  // }

  init() {
    // let tab_activated = false;
    // Array.prototype.forEach.call(this.tabTargets, function (tab, index) {
    //   if (last_active_tab) {
    //     if (tab.getAttribute("id") == last_active_tab) {
    //       let content_id = tab.getAttribute("href");
    //       if (document.querySelector(content_id)) {
    //         document.querySelector(content_id).classList.add("active");
    //       }
    //       tab.classList.add("active");
    //       tab_activated = true;
    //     } else {
    //       tab.classList.remove("active");
    //     }
    //   } else {
    //     if (index == 0) {
    //       let content_id = tab.getAttribute("href");
    //       if (document.querySelector(content_id)) {
    //         document.querySelector(content_id).classList.add("active");
    //       }
    //       tab.classList.add("active");
    //       tab_activated = true;
    //     }
    //   }
    // });

    // if (!tab_activated) {
    //   let tab = this.tabTargets[0];
    //   let content_id = tab.getAttribute("href");
    //   if (document.querySelector(content_id)) {
    //     document.querySelector(content_id).classList.add("active");
    //   }
    //   tab.classList.add("active");
    // }
    //
    // todo init
    //
    // let updateSidebarStick = super.updateSidebarStick
    Array.prototype.forEach.call(this.tabTargets, function (tab, index) {
      new BSN.Tab(tab, { height: true });

      // tab.addEventListener('show.bs.tab', function (event) {
      //   console.log(1)
      //   updateSidebarStick()
      //   console.log(2)
      //   // do some cool stuff
      //   // event.target is myLastTab
      //   // event.relatedTarget is the previous active tab
      // }, false);
    });
  }

}
