import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["content", "active_load_content"]

  initialize() {
    // console.log("load initialize")
    let content_url = this.data.get("content-url");
    if (this.hasContentTarget) {
      this.setContent(content_url, this.contentTarget)
    }

    if (this.hasActive_load_contentTarget) {
      const element_id = this.data.get("activate-element-id");
      const element = document.getElementById(element_id)
      const controller = this;
      element.addEventListener("click", function handler(event) {
        controller.setContent(content_url, controller.active_load_contentTarget);
        // element.removeEventListener("click", handler);
      });
    }
  }

  reload(content_target) {
    let content_url = content_target.getAttribute("data-layout--load-content-url");
    this.setContent(content_url, content_target)
  }

  async setContent(content_url, content_target) {
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
  }
}
